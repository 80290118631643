.col-lg-8 {
 max-width: 90%;
}

header.masthead .post-heading,
header.masthead .page-heading {
 padding-top: 100px;
 padding-bottom: 12px;
 text-align: center;
}

header.masthead,
header.masthead .page-heading {
 background-color: white;
 color: black;
 margin-bottom: 12px;

}

header.masthead .overlay {
 background-color: white;
}


header.masthead .post-heading,
header.masthead .post-heading .meta a,
#mainNav .navbar-brand,
#mainNav .navbar-brand:hover,
#mainNav .navbar-nav > li.nav-item > a,
#mainNav .navbar-nav > li.nav-item > a:hover
{
 color: black;
}

div.container img {
 max-width: 100%;
}

table td, table th {
 border-left: 1px solid grey;
 border-right: 1px solid grey;
 padding-right: 12px;
 padding-left: 12px;
 padding-bottom: 12px;
}

table th {
 border-bottom: 1px solid grey;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table {
  margin: 24px;
}
